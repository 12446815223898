@use '../../../globalStyles/mixins' as m;
@use '../../../globalStyles/variables' as v;

.education {
  width: 100%;
  height: 100%;
  display: flex;
  user-select: none;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: v.$border-radius;
  box-sizing: border-box;
  gap: 1rem;

  .item__details {
    width: 100%;

    .title {
      font-size: m.size(large-0);
      font-weight: m.weight(medium);
      margin: 0 0 0.25rem 0;
    }

    .term {
      font-size: m.size(small-0);
    }

    .description {
      color: m.color(grey-8);
    }
  }

  &::before {
    content: '';
    width: 5px;
    background: m.color(grey-4);
  }

  .project__links {
    width: 100%;
    flex-basis: 13%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: center;

    .github-logo {
      display: block;
      background-image: url('/assets/static/images/github-light.png');
      background-repeat: no-repeat;
      background-size: contain;
      width: 1.5rem;
      height: 1.5rem;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  .blog__links {
    width: 100%;
    flex-basis: 5%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: center;

    .blog-logo {
      display: block;
      background-image: url('/assets/static/images/logomark.png');
      background-repeat: no-repeat;
      background-size: contain;
      width: 2rem;
      height: 2rem;
    }

    &:hover {
      opacity: 0.6;
    }
  } 
}