@use "../../../globalStyles/mixins" as m;
@use "../../../globalStyles/variables" as v;

.experience {
  width: 100%;
  height: 100%;
  min-height: 120px;
  display: flex;
  user-select: none;
  margin-bottom: 1.375rem;

  padding: 1rem;
  border-radius: v.$border-radius;
  border: 1px solid m.color(grey-2);
  box-sizing: border-box;
  gap: 1rem;
  .details-info__wrapper {
    height: 0;
    visibility: hidden;
    overflow: hidden;
    transition: height 0.35s ease, visibility 0.35s ease;
  }
  .details-info {
    margin-top: 1.3rem;
    border-top: 1px solid m.color(grey-2);
  }
  .details-header {
    display: flex;
    align-items: center;
  }
  .details-header__contents {
    border-right: 1px solid m.color(grey-2);
    padding-right: 10px;
  }
  .arrow {
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 120px;
    margin-left: 10px;
    cursor: pointer;
  }
  .item__image {
    min-width: 4rem;
    max-width: 6rem;
    border: 1px solid m.color(grey-0);
    border-radius: v.$border-radius;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;

    img {
      object-fit: contain;
      box-shadow: v.$box-shadow;
      width: 100%;
      height: auto;
      border-radius: v.$border-radius;
      background: m.color(white);
    }
  }

  .github_link {
    width: 8%;
    flex-basis: 5%;
    text-align: left;
    display: flex;
    align-items: left;
    justify-content: left;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0px;

    .github-logo {
      display: block;
      background-image: url('/assets/static/images/github-light.png');
      background-repeat: no-repeat;
      background-size: contain;
      width: 1.5rem;
      height: 1.5rem;
    }
    
    &:hover {
      opacity: 0.6;
    }
  }
  
  .blog_link {
    width: 8.5%;
    flex-basis: 5%;
    text-align: left;
    display: flex;
    align-items: left;
    justify-content: left;
    margin-top: -48px;
    margin-left: 42px;
  
    .blog-logo {
      margin-left: 2px;
      display: inline-block;
      background-image: url('/assets/static/images/logomark.png');
      background-repeat: no-repeat;
      background-size: contain;
      width: 2rem;
      height: 2rem;
    }
    &:hover {
      opacity: 0.6;
    }
  }

  .item__details {
    width: 100%;
    height: 100%;

    .title {
      font-size: m.size(large-0);
      font-weight: m.weight(medium);
      margin: 0 0 0.25rem 0;
    }

    .description {
      color: m.color(grey-11);
      margin-left: 2px;
    }

    .sub_project_title {
      font-size: 1.1rem;
      font-weight: 480;
      margin: 1.6rem 0rem -0.4rem 0;
    }

    .term {
      font-size: m.size(small-0);
      color: m.color(grey-8);
      margin-left: 2px;
    }

    .sub_title {
      font-size: 1.01rem;
      font-weight: 480;
      margin: 1.3rem 0rem 0.2rem 0;
    }

    .sub_detail {
      font-size: 0.95rem;
      color: m.color(grey-10);
      margin: 0rem 0rem 0.3rem 0;
    }

    .sub_summary {
      font-size: 0.95rem;
      color: m.color(grey-10);
      margin: 1rem 0rem 1.1rem 0;
    }

    .sub_detailList {
      font-size: 0.95rem;
      color: m.color(grey-10);
      margin: 0rem 0rem 1.1rem 0;
    }

    .space {
      margin: 2.5rem 0rem 2rem 0;
    }

    .oneLine {
      display: inline;
    }

    .related_link {
      display: inline;
      margin-top: 1.3rem;
      color: hsl(30, 100%, 50%);
      // color: #FFB026;
      opacity: 1;  
      &:hover {
        opacity: 0.4;
      }
    }
  }

  .tags {
    display: flex;

    .tag {
      @include m.tech-tags;
    }
  }

}


@include m.mobile-down {
  .experience {
    flex-direction: column;
    padding: 0;
    border: 0;
    grid-gap: 0.225rem;
    gap: 0.225rem;

    .item__details {
      padding-top: 0.5rem;
      padding-left: 0.625rem;

      .title {
        border-bottom: 1px solid m.color(grey-2);
        padding-bottom: 0.225rem;

        span {
          display: block;
        }
      }
    }
  }
}

@include m.mobile {
  .experience {
    flex-direction: column;
    padding: 0;
    border: 0;
  }
}
