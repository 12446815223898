@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "./globalStyles/variables";
@import "./globalStyles/mixins";

* {
  font-family: Montserrat, "Noto Sans KR", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  min-height: 100%;
  cursor: default;
  margin: 0;
  padding: 0;
  word-break: keep-all;
  text-align: justify;
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

[data-theme="dark-mode"] {
  font-weight: weight(light);

  background-color: color(main);
  color: color(grey-light);

  .subtitle__h3 {
    text-shadow: -0px 0 10px color(dark-event), 2px 2px color(primary-emphasis);
  }

  .project .project__links .github-logo {
    background-image: url("./assets/static/images/github-dark.png");
  }

  .timestamp .timestamp__main .when {
    color: color(grey-1);
  }

  .experience {
    border-color: color(grey-10);

    &:hover {
      border-color: color(grey-11);
    }
  }

  .footer-container .footer-contents {
    color: color(white);

    &:hover {
      color: color(primary);
    }
  }

  .skill-sets-container .description {
    background: color(grey-11);

    .description-item {
      color: color(white);
    }

    .expert {
      background-color: color(primary-emphasis-light);
    }

    &::before {
      background: color(grey-8);
    }
  }

  .container {
    .language {
      select {
        background: transparent;
        color: color(white);
      }
    }

    .theme-container {
      button {
        background-image: url("./assets/static/images/sun.png");
      }
    }
  }

  .about-me-container .introduce .about {
    strong {
      background-color: color(primary-opacity-heavy-light);
    }

    p {
      gap: 0.1rem;
    }

    boldred {
      background-color: color(alert-opacity-light);
    }

    boldblue {
      background-color: color(dark-event-opacity-light);
    }

    boldgreen {
      background-color: color(safe-opacity-light);
    }

    ul > customli {
      &::before {
        background-image: url("./assets/static/images/check-dark.png");
      }
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;

  &:visited {
    color: inherit;
  }
}

li {
  list-style: none;
}

button {
  cursor: pointer;
  background: transparent;
  border: 0;
}

h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: middle;
}
